import styles from './Layout.module.scss'
import React from 'react'
import Footer from '../Footer/Footer'

const Layout: React.FC = ({ children }) => {
    return (
        <>
            <div className={styles.container}>
                {children}
                <Footer />
            </div>
        </>
    )
}

export default Layout
