import '../styles/globals.scss'
import '@solana/wallet-adapter-react-ui/styles.css'
import 'nprogress/nprogress.css'

import type { AppProps } from 'next/app'
import Layout from '../components/Layout/Layout'
import React, { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { Router } from 'next/router'
import NProgress from 'nprogress'
import Modal from 'react-modal'
import TagManager from 'react-gtm-module'
import WalletConnectionProvider from '../components/Wallet/WalletConnectionProvider'

Modal.setAppElement('#__next')

NProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function MyApp({ Component, pageProps }: AppProps) {
    useEffect(() => {
        TagManager.initialize({
            gtmId: 'GTM-K5JZVDW',
        })
    }, [])
    return (
        <WalletConnectionProvider>
            <Layout>
                <Component {...pageProps} />
                <Toaster />
            </Layout>
        </WalletConnectionProvider>
    )
}

export default MyApp
