import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets'

import { clusterApiUrl } from '@solana/web3.js'
import { FC, ReactNode, useMemo } from 'react'

const WalletConnectionProvider: FC<{ children: ReactNode }> = ({ children }) => {
    // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
    const network = process.env.NEXT_PUBLIC_WALLET_ADAPTER_NETWORK as WalletAdapterNetwork.Devnet
    const cluster = process.env.NEXT_PUBLIC_WALLET_ADAPTER_ENDPOINT as string

    if (!network) {
        throw new Error('No Wallet Adapter Network set')
    }

    const endpoint = useMemo(() => (cluster ? cluster : clusterApiUrl(network)), [cluster, network])

    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter(),
            // getTorusWallet({
            //     options: { clientId: 'Get a client ID @ https://developer.tor.us' },
            // }),
            // allWallets,
            // getSolletWallet({ network }),
            // getSolletExtensionWallet({ network }),
        ],
        [network]
    )

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}

export default WalletConnectionProvider
