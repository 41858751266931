import { LazyLoadImage } from 'react-lazy-load-image-component'
// import 'react-lazy-load-image-component/src/effects/blur.css'

import React from 'react'

interface Props {
    className?: string
    alt?: string
    src: string
}

const LazyImage: React.FC<Props> = ({ className, alt = '', src }) => {
    return <LazyLoadImage alt={alt} src={src} className={className} />
}

export default LazyImage
